.App {
  text-align: center;
}

.Orator {
  font-family: 'Orator' !important;
}

.Portfolio{
  padding: 1rem;
  animation: 8s ease 0s normal forwards 1 fadeInDelay;
}

.FadeIn {
  animation-name: fadeIn;
  animation-duration: 4s;
}

.FadeInDelay {
  animation: 5s ease 0s normal forwards 1 fadeInDelay;
}


@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeInDelay{
  0% { opacity:0; }
  50% { opacity:0; }
  100% { opacity:1; }
}